import { useProductManagerActions } from "@iso/actions/productManager.actions";
import { Card, Form, Input, message, Space, Spin, Switch } from "antd";
import { useEffect, useState } from "react";
import AsyncButton from "component/AsyncButton";
import styled from "styled-components";
import { useLangManagerActions } from "@iso/actions/langManager.actions";

const WrapperForm = styled(Form)`
   .ant-form-item-label {
      min-width: 200px;
      text-align: left;
   }
`;

function CookingProfile(props) {
   const { product } = props;
   const [form] = Form.useForm();
   const [isSubmitting, setIsSubmitting] = useState({
      delete: false,
      submit: false,
   });
   const [isLoading, setIsLoading] = useState(false);
   const [dataProduct, setDataProduct] = useState(null);
   const [dataLangs, setDataLangs] = useState(null);
   const productManagerActions = useProductManagerActions();
   const langManagerActions = useLangManagerActions();

   const getLangs = async () => {
      try {
         setIsLoading(true);
         const res = await langManagerActions.getLangList();
         const langs = res?.sort((a, b) =>
            a?.iso_code?.localeCompare(b?.iso_code)
         );
         setDataLangs(langs);
         setIsLoading(false);
      } catch (err) {
         setIsLoading(false);
      }
   };

   const getDataProduct = async () => {
      if (product?.id) {
         setIsLoading(true);
         try {
            const resGet = await productManagerActions.getProductDetail(
               product?.id
            );
            resGet.productId = resGet?.id;
            resGet.category_id = resGet?.category?.id;
            resGet.class_id = resGet?.class?.id;
            form.setFieldsValue({
               ...resGet,
               items: resGet?.productItemCookingLang?.sort((a, b) =>
                  a?.iso_code?.localeCompare(b?.iso_code)
               ),
            });
            setDataProduct(resGet);
            setIsLoading(false);
         } finally {
            setIsLoading(false);
         }
      } else {
         setDataProduct(null);
         form.resetFields();
      }
   };

   const handleUpdate = async (values) => {
      try {
         setIsSubmitting((state) => ({ ...state, submit: true }));
         await productManagerActions.updateProductCookingItemLang(
            values?.items?.map((item) => ({ ...item, item_id: product?.id }))
         );
         message.success("Update product success!!!");
      } finally {
         setIsSubmitting((state) => ({ ...state, submit: false }));
      }
   };

   useEffect(() => {
      if (product?.id) {
         getDataProduct();
      } else {
         form.resetFields();
         form.setFieldsValue({ items: [] });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [product?.id]);

   useEffect(() => {
      if (dataLangs?.length && !product?.id) {
         form.setFieldsValue({
            items: dataLangs?.map((item) => ({
               iso_code: item?.iso_code,
               add_time_per_dish: 0,
               max_grouping: 0,
               is_pizza: false,
               is_loop: false,
               is_dessert: false,
            })),
         });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [product?.id, dataLangs]);

   useEffect(() => {
      getLangs();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <>
         <Spin spinning={isLoading}>
            <div className="">
               <WrapperForm
                  name="product-form"
                  onFinish={handleUpdate}
                  form={form}
               >
                  <Form.List name="items">
                     {(fields, { add, remove }) => (
                        <Space
                           className="w-full"
                           direction="vertical"
                           size="small"
                        >
                           {fields.map(({ key, name, ...restField }) => {
                              return (
                                 <Card
                                    title={
                                       dataLangs &&
                                       dataLangs?.length &&
                                       dataLangs[key]
                                          ? dataLangs[key]?.name
                                          : ""
                                    }
                                    className="shadow-sm mb-8"
                                 >
                                    <div className="grid grid-cols-2 gap-20 bg-white-0 rounded-sm mt-8">
                                       <div>
                                          <Form.Item
                                             {...restField}
                                             name={[key, "standard_time"]}
                                             label="Standard time"
                                             rules={[
                                                {
                                                   required: true,
                                                   message:
                                                      "Standard time is required!",
                                                },
                                             ]}
                                          >
                                             <Input placeholder="Standard time" />
                                          </Form.Item>
                                          <Form.Item
                                             name={[key, "add_time_per_dish"]}
                                             label="Add time per dish"
                                             rules={[
                                                {
                                                   required: true,
                                                   message:
                                                      "Add time per dish is required!",
                                                },
                                             ]}
                                          >
                                             <Input
                                                type="number"
                                                placeholder="Add time per dish"
                                             />
                                          </Form.Item>
                                          <Form.Item
                                             name={[key, "priority"]}
                                             label="Priority"
                                          >
                                             <Input
                                                type="number"
                                                placeholder="Priority"
                                             />
                                          </Form.Item>
                                          <Form.Item
                                             name={[key, "is_detach"]}
                                             label="Detach"
                                             valuePropName="checked"
                                          >
                                             <Switch />
                                          </Form.Item>
                                          <Form.Item
                                             name={[key, "is_pizza"]}
                                             label="Pizza"
                                             valuePropName="checked"
                                          >
                                             <Switch />
                                          </Form.Item>
                                       </div>
                                       <div>
                                          <Form.Item
                                             name={[key, "prepare_time"]}
                                             label="Prepare time"
                                             rules={[
                                                {
                                                   required: true,
                                                   message:
                                                      "Prepare time per dish is required!",
                                                },
                                             ]}
                                          >
                                             <Input
                                                type="number"
                                                placeholder="Prepare time"
                                             />
                                          </Form.Item>
                                          <Form.Item
                                             name={[key, "max_grouping"]}
                                             label="Max grouping"
                                          >
                                             <Input
                                                type="number"
                                                placeholder="Max grouping"
                                             />
                                          </Form.Item>
                                          <Form.Item
                                             name={[key, "is_loop"]}
                                             label="Loop"
                                             valuePropName="checked"
                                          >
                                             <Switch />
                                          </Form.Item>
                                          <Form.Item
                                             name={[key, "is_dessert"]}
                                             label="Dessert"
                                             valuePropName="checked"
                                          >
                                             <Switch />
                                          </Form.Item>
                                       </div>
                                    </div>
                                 </Card>
                              );
                           })}
                        </Space>
                     )}
                  </Form.List>
               </WrapperForm>
            </div>
         </Spin>
         {dataProduct && (
            <div className="mt-8 bg-white-0 p-8 text-right flex justify-end shadow-sm">
               <div className="ml-16">
                  <AsyncButton
                     isLoading={isSubmitting?.submit}
                     type="primary"
                     onClick={() => form.submit()}
                  >
                     Submit
                  </AsyncButton>
               </div>
            </div>
         )}
      </>
   );
}

export default CookingProfile;
