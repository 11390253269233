import { API_ENDPOINT } from "@iso/config/index";
import { axiosClient } from "@iso/helpers/axios.config";

const url = `${API_ENDPOINT}`;

const menuDisplayByodApi = {
   getGrandMenu: (params) => {
      return axiosClient.get(`${url}/menu-display`, { params });
   },
   getMenuChildOfMenuDisplay: (params) => {
      return axiosClient.get(`${url}/menu-display/child-menu-display`, { params });
   },
   getMenus: (params) => {
      return axiosClient.get(`${url}/menu-display/menus`, { params });
   },
   getRecommends: (params) => {
      return axiosClient.get(`${url}/menu-display/recommends`, { params });
   },
   getMainMenuDisplay: (params) => {
      return axiosClient.get(`${url}/menu-display/main-menu-display`, { params });
   },
   getMenuDisplayByParent: (params) => {
      return axiosClient.get(`${url}/menu-display/by-parents`, { params });
   },
   getCateNoneParent: (params) => {
      return axiosClient.get(`${url}/menu-display/none-parents`, { params });
   },
   putMenuDisplay: (body) => {
      return axiosClient.put(`${url}/menu-display`, body);
   },
   postMenuItemDisplay: (body) => {
      return axiosClient.post(`${url}/menu-item-display`, body);
   }
};

export default menuDisplayByodApi;