import { API_BYOD_ENDPOINT, API_ENDPOINT } from "@iso/config/index";
import { axiosClient } from "@iso/helpers/axios.config";

const url = `${API_ENDPOINT}`;

const menuDisplayByodApi = {
    getGrandMenu: (params) => {
        return axiosClient.get(`${url}/menu-display`, { params });
    },
    getCateNoneParent: (params) => {
        return axiosClient.get(`${url}/menu-display/none-parents`, { params });
    },
    putMenuDisplay: (body) => {
        return axiosClient.put(`${url}/menu-display`, body);
    },
};

export default menuDisplayByodApi;
