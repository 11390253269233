export const API_ENDPOINT = "https://uat-mms.pizza4ps.io/mms/api";
export const API_FEEDBACK = "https://uat-fb-api.pizza4ps.io/feedback/api";
export const API_LOYALTY_BO_ENDPOINT = "https://loyalty.pizza4ps.io/loyalty-bo-api/api";
export const API_ENDPOINT_BO_DELI = "https://uat-deli-v2.pizza4ps.io";
export const API_KMS = "https://uat-kvs.pizza4ps.io/api/kvs";

export const firebaseConfig = {
   apiKey: "AIzaSyDbFXVnMR3zoq3UT2U8D5lU8yEJiLcVpSA",
   authDomain: "map-4ps-prod.firebaseapp.com",
   databaseURL: "map-4ps-prod.firebaseapp.com",
   projectId: "map-4ps-prod",
   storageBucket: "map-4ps-prod.appspot.com",
   messagingSenderId: "1074448014141",
   appId: "1:1074448014141:web:964da611c796d5c6518354",
   measurementId: "G-8B4C5LN45B",
};

export const VAPI_KEY_FIREBASE = "";


export const DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm";

export const DATE_FORMAT = "DD/MM/YYYY";

export const UNLIMITED_ADD_ON_SELECTION = -1;

export const FILTER_TYPE = {
   SELECT: "select",
};

export const ADD_ON_TYPE = {
   COMBO: 0,
   SINGLE: 1,
   MULTIPLE: 2,
};

export const MAX_SELECTION_ADD_ON_TYPE = {
   UNLIMITED: "unlimited",
   LIMIT: "limit",
};

export const ADD_ON_TYPE_NAME = {
   COMBO: "Combo",
   SINGLE: "Required",
   MULTIPLE: "Optional",
};

export const COUNTRY_CODE = {
   VN: "VN",
   IN: "IN",
   JP: "JP",
};

export const COUNTRY_NAME = {
   VN: "Vietnam",
   IN: "India",
   JP: "Japan",
};

export const SYS_CONTANT = [
   {
      label: "Delivery System",
      value: "DELI",
      links: "/deli/csa",
      role: "deli_system",
   },
   { label: "BYOD System", value: "BYOD", links: "/byod", role: "byod_system" },
   {
      label: "Feedback System",
      value: "Feedback",
      links: "/feedback",
      role: "feedback_system",
   },
   {
      label: "Master Management System",
      value: "MMS",
      links: "/mms",
      role: "mms_system",
   },
   {
      label: "Kitchen Management System",
      value: "KVS",
      links: "/kvs",
      role: "kms_system",
   },
   {
      label: "Loyalty Management System",
      value: "Loyalty",
      links: "/loyalty",
      role: "loyalty_system",
   },
];

export const STYLE_TYPE_BANNER = [
   {
      value: "horizontal",
      label: "Horizontal",
   },
   {
      value: "vertical",
      label: "Vertical",
   },
];

export const VIEW_TYPE_BANNER = [
   {
      value: "banner",
      label: "Banner",
   },
   {
      value: "popup",
      label: "Popup",
   },
];

export const MENU_IDS = {
   DRINK_RECOMMEND_ID: "acec008f-1349-473a-ba60-4fced8b19ba7",
   FOOD_RECOMMEND_ID: "f4b3b3b4-1b3b-4b3b-8b3b-3b3b3b3b3b3b",
}