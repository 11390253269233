import { API_BYOD_ENDPOINT, API_ENDPOINT } from "@iso/config/index";
import { axiosClient } from "@iso/helpers/axios.config";

const url = `${API_ENDPOINT}`;

const menuItemDisplayApi = {
    postMenuItemDisplay: (body) => {
        return axiosClient.post(`${url}/menu-item-display`, body);
    }
};

export default menuItemDisplayApi;
