import React, { useState, useEffect, useContext, useMemo } from "react";
import { Tooltip, Table, Button, InputNumber, Input, Typography, Popconfirm, message } from "antd";
import { useMenuDisplayManagerActions } from "@iso/actions/menuDisplayManager.actions.js";
import { useStoreManagerActions } from "@iso/actions/storeManager.actions";
import Page from "component/Page/index";
import { CloseOutlined, EditOutlined, HolderOutlined } from "@ant-design/icons";
import { Select, Form } from "antd";
import { CSS } from '@dnd-kit/utilities';

import { AppHelper } from 'helpers/helper.js';
import { IMG_NONE } from 'utils/constant.js';
import {
   arrayMove,
   SortableContext,
   useSortable,
   verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';


const { Option } = Select;

const RowContext = React.createContext({});
const DragHandle = () => {
   const { setActivatorNodeRef, listeners } = useContext(RowContext);
   return (
      <Button
         type="text"
         size="small"
         icon={<HolderOutlined />}
         style={{
            cursor: 'move',
         }}
         ref={setActivatorNodeRef}
         {...listeners}
      />
   );
};

const Row = (props) => {
   const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging,
   } = useSortable({
      id: props['data-row-key'],
   });
   const style = {
      ...props.style,
      transform: CSS.Translate.toString(transform),
      transition,
      ...(isDragging
         ? {
            position: 'relative',
            zIndex: 9999,
         }
         : {}),
   };
   const contextValue = useMemo(
      () => ({
         setActivatorNodeRef,
         listeners,
      }),
      [setActivatorNodeRef, listeners],
   );
   return (
      <RowContext.Provider value={contextValue}>
         <tr {...props} ref={setNodeRef} style={style} {...attributes} />
      </RowContext.Provider>
   );
};


const MenuMaster = () => {
   const [isLoading, setIsLoading] = useState(true);
   const [menuItemNoneStore, setMenuItemNoneStore] = useState({
      search: null,
      data: [],
   });
   const [productNoneItems, setProductNoneItems] = useState({
      search: null,
      data: [],
   });
   const [menuDisplay, setMenuDisplay] = useState([]);
   const [menuItem, setMenuItem] = useState([]);
   const [listStoreChannel, setListStoreChannel] = useState([]);
   const [storeId, setStoreId] = useState(null);
   const [activeMenu, setActiveMenu] = useState(null);
   const menuDisplayManagerActions = useMenuDisplayManagerActions();
   const storeManagerActions = useStoreManagerActions();

   const [form] = Form.useForm();
   const [editingKey, setEditingKey] = useState('');
   const isEditing = (record) => record.id === editingKey;
   const [deletedRows, setDeletedRows] = useState([]);



   const edit = (record) => {
      form.setFieldsValue({
         name: '',
         age: '',
         address: '',
         ...record,
      });
      setEditingKey(record.id);
   };
   const cancel = () => {
      setEditingKey('');
   };
   const save = async (id) => {
      try {
         const row = await form.validateFields();
         const newData = [...menuItem];
         const index = newData.findIndex((item) => id === item.id);
         if (index > -1) {
            let item = newData[index];
            item.isUpdate = true;
            let newProductItems = { ...item.productItems, ...row.productItems };
            item.productItems = newProductItems;
            item.note = row.note;
            newData.splice(index, 1, {
               ...item,
            });
            setMenuItem(newData);
            setEditingKey('');
         } else {
            newData.push(row);
            setMenuItem(newData);
            setEditingKey('');
         }
      } catch (errInfo) {
         console.log('Validate Failed:', errInfo);
      }
   };

   const remove = (id) => {
      const newData = [...menuItem];
      const index = newData.findIndex((item) => id === item.id);
      if (index > -1) {
         newData.splice(index, 1);
         setMenuItem(newData);
         setDeletedRows([...deletedRows, {
            id: newData[index].id,
            is_active: false,
            price: parseFloat(newData[index].productItems.base_price),
            tax: newData[index].productItems.tax_percentage,
            sorting: -1,
         }]);
      }
   };


   // const DragHandle = SortableHandle(() => (
   //    <MenuOutlined
   //       style={{
   //          cursor: "grab",
   //          color: "#999",
   //       }}
   //    />
   // ));
   const menuItemsColumns = [
      // {
      //    title: "Sort",
      //    key: 'sort',
      //    align: 'center',
      //    width: 80,
      //    render: () => <DragHandle />,
      // },
      {
         title: "Menu Item Note",
         dataIndex: "note",
         key: "note",
         editable: true,
         render: (record) => {
            return record || '';
         },
      },
      {
         title: "Item Name",
         dataIndex: "productItems",
         key: "name",
         render: (record) => record.name,
         // inputType: 'select',
         // editable: true,
      },
      {
         title: "Price",
         dataIndex: ["productItems", "base_price"],
         key: "base_price",
         align: "center",
         editable: true,
         width: 250,
      },
      {
         title: "Tax",
         dataIndex: ["productItems", "tax_percentage"],
         key: "tax_percentage",
         align: "center",
         editable: true,
         width: 150,
      },
      {
         title: 'operation',
         dataIndex: 'operation',
         width: 250,
         render: (_, record) => {
            const editable = isEditing(record);
            return editable ? (
               <span className="flex gap-3 items-center">
                  <Typography.Link
                     onClick={() => save(record.id)}
                     style={{
                        marginRight: 8,
                     }}
                  >
                     Save
                  </Typography.Link>
                  <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                     <a>Cancel</a>
                  </Popconfirm>
               </span>
            ) : (
               <span className="flex gap-3 items-center">
                  <Button
                     onlyIcon
                     type="primary"
                     size="small"
                     style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "0",
                     }}
                     disabled={editingKey !== ''} onClick={() => edit(record)}
                  >
                     <div className="flex items-center">
                        <EditOutlined />
                     </div>
                  </Button>
                  <span className="flex gap-3 items-center">
                     <Popconfirm title="Sure to remove?" onConfirm={() => remove(record.id)}>
                        <Button
                           onlyIcon
                           type="primary"
                           size="small"
                           style={{
                              display: "flex !important",
                              alignItems: "center",
                              margin: "0",
                           }}
                           // disabled={editingKey !== ''}
                           disabled={record?.item_id}
                        >
                           <CloseOutlined />
                        </Button>
                     </Popconfirm>
                  </span>
               </span>
            );
         },
      },
   ];


   const mergedColumns = menuItemsColumns.map((col) => {
      if (!col.editable) {
         return col;
      }
      return {
         ...col,
         onCell: (record) => {
            return {
               record,
               inputType: 'text',
               dataIndex: col.dataIndex,
               title: col?.title,
               editing: isEditing(record) || false,
            }
         },
      };
   });

   const EditableCell = ({
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
   }) => {
      const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;

      return (
         <td {...restProps}>
            {editing ? (
               <Form.Item
                  name={dataIndex}
                  style={{
                     margin: 0,
                  }}
                  rules={[
                     {
                        required: true,
                        message: `Please Input ${title}!`,
                     },
                  ]}
               >
                  {inputNode}
               </Form.Item>
            ) : (
               children
            )}
         </td>
      );
   };


   const getMenuItemNoneStore = async () => {
      setIsLoading(true);
      try {
         const dataGet = await menuDisplayManagerActions.getMenuItemsNoneStore();
         if (dataGet) {
            setMenuItemNoneStore({ ...menuItemNoneStore, data: dataGet });
            console.log(dataGet)
            setMenuItem([...menuItem, ...dataGet]);
         }
      } catch (error) {
         console.log(error);
      } finally {
         setIsLoading(false);
      }
   };

   const getProductNoneItems = async () => {
      setIsLoading(true);
      try {
         const dataGet = await menuDisplayManagerActions.getProductNoneItems();
         if (dataGet) {
            setProductNoneItems({ ...productNoneItems, data: dataGet });
         }
      } catch (error) {
         console.log(error);
      } finally {
         setIsLoading(false);
      }
   };

   useEffect(() => {
      getProductNoneItems();
      getMenuItemNoneStore();
   }, []);

   const handleAddMenuItem = (id) => {
      if (!productNoneItems.data.length) return;
      let find = productNoneItems.data.find(item => item.id === id);
      if (!find) return;
      find.base_price = parseFloat(find.base_price);
      setMenuItem([{ id: find.id, productItems: find, note: '', }, ...menuItem]);
   }

   const handleGetMenuItem = async (id) => {
      const dataGet = await menuDisplayManagerActions.getMenuDisplayById(id);
      if (dataGet && dataGet.menuItem.length) {
         setMenuItem(dataGet.menuItem);
         setActiveMenu(id);
      }
   }

   const onSearch = (val, key) => {
      if (key === 'menuDisplays') {
         setMenuItemNoneStore({ ...menuItemNoneStore, search: val });
      }
      if (key === 'productNoneItems') {
         setProductNoneItems({ ...productNoneItems, search: val });
      }
   };


   const onDragEnd = ({ active, over }) => {
      if (active.id !== over?.id) {
         setMenuItem((prevState) => {
            const activeIndex = prevState.findIndex((record) => record.id === active?.id);
            const overIndex = prevState.findIndex((record) => record.id === over?.id);
            return arrayMove(prevState, activeIndex, overIndex);
         });
      }
   };
   function format_number(num = 0) {
      return num?.toString()?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
   }
   const handleSubmit = async () => {
      const cloneMenuItem = JSON.parse(JSON.stringify(menuItem));
      let body = []
      cloneMenuItem.forEach((item, index) => {
         console.log(item)
         body.push({
            id: item?.item_id ? item.id : null,
            item_id: item?.productItems?.id,
            sorting: index + 1,
            // is_active: true,
            is_active: false,
            selling_price: parseFloat(item?.productItems?.base_price),
            selling_tax_percentage: parseFloat(item.productItems.tax_percentage),
            quantity_in_stock: parseFloat(item?.productItems?.quantity_in_stock),
            quantity_sold: parseFloat(item?.productItems?.quantity_sold),
            note: item?.note,
            is_recommended: item?.productItems?.is_recommended
         })
      })

      // body = [...body, ...deletedRows];
      body = [...body];
      try {
         const res = await menuDisplayManagerActions.putMenuItemDisplay(body).catch(error => { throw error });
         console.log(res)
         if (!res.status && res.status !== false) {
            message.success("Update success");
            getMenuItemNoneStore();
         }
      } catch (error) {
         message.error(error?.message || "Update failed")
         return;
      }


   }
   return (
      <Page title="Menu Master">
         <div className="grid grid-cols-12 gap-4">
            {/* <Select
               className="col-span-4"
               placeholder="Menu item none store"
               optionFilterProp="children"
               allowClear
               dropdownStyle={{ zIndex: "999 !important" }}
               onSelect={handleMenuItemNoneStore}
            >
               {menuItemNoneStore && menuItemNoneStore.data.length &&
                  menuItemNoneStore.data.map((item) => (
                     <Option value={item?.id} key={item.id}>
                        {item?.note}
                     </Option>
                  ))}
            </Select> */}
            <Select
               className="col-span-4"
               placeholder="Product none Items"
               showSearch
               optionFilterProp="children"
               allowClear
               autoClearSearchValue
               onChange={handleAddMenuItem}
               onSearch={e => onSearch(e, 'productNoneItems')}
            >
               {productNoneItems && productNoneItems.data.length &&
                  productNoneItems.data.map((item) => (
                     <Option value={item?.id} key={item.id}>
                        {item?.name}
                     </Option>
                  ))}
            </Select>
            {/* <Select
               className="col-span-4"
               placeholder="Menu Items"
               showSearch
               optionFilterProp="children"
               allowClear
               autoClearSearchValue
               onChange={handleGetMenuItem}
               onSearch={e => onSearch(e, 'menuItems')}

            >
               {menuDisplay && menuDisplay.length &&
                  menuDisplay.map((item) => (
                     <Option value={item?.id} key={item.id}>
                        {item?.displays.name}
                     </Option>
                  ))}
            </Select> */}

         </div>
         <div className="flex gap-6 mt-5 relative z-0">
            <Form form={form} component={false}>
               <div className="flex-1">
                  <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
                     <SortableContext items={menuItem.map((i) => i.id)} strategy={verticalListSortingStrategy}>
                        <Table
                           rowKey="id"
                           components={{
                              body: {
                                 row: Row,
                                 cell: EditableCell,
                              },
                           }}
                           loading={isLoading}
                           // columns={menuItemsColumns}
                           columns={mergedColumns}
                           dataSource={menuItem}
                           pagination={false}
                        />
                     </SortableContext>
                  </DndContext>
               </div>
               <div className="w-1/4">
                  <div className="mb-4">
                     <Button onClick={handleSubmit}>Submit</Button>
                  </div>
                  <div className="wrapper-preview rounded-lg">
                     <div className="menu-display">
                        {menuDisplay?.map((item, index) => (
                           <div key={index} className={`mb-8 ${activeMenu == item.id ? 'active' : ""}`} onClick={() => handleGetMenuItem(item.id)}>
                              <img src={item?.productItems?.image_url || IMG_NONE} alt={item?.productItems.name} />
                              <h4 className={`${activeMenu == item.id ? 'font-bold' : ""}`}>{item?.productItems.name}</h4>
                           </div>
                        ))}
                     </div>
                     <div className="menu-item">
                        <div className="grid grid-cols-12 gap-4">
                           {menuItem?.map((item) => (
                              <div key={item?.id} className="col-span-6">
                                 <img src={item?.productItems?.image_url || IMG_NONE} alt={item?.productItems?.name} />
                                 <Tooltip title={item?.productItems?.name}><h4>{AppHelper.shortenString(item?.productItems?.name, 15)}</h4></Tooltip>
                                 <div className="money">{format_number(parseFloat(item?.productItems?.base_price))} vnđ</div>
                              </div>
                           ))}
                        </div>
                     </div>
                  </div>
               </div>
            </Form>
         </div>
      </Page>
   );
};

export default MenuMaster;
