import { Button, Form, Input, Tabs } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useState } from "react";
import GeneralInfomation from "./Genetal";
import Attribute from "./Attribute";
import Availability from "./Availability";
import Page from "component/Page";
import Customization from "./Customization";
import Content from "./Content";
import History from "./History";
import CookingProfile from "./CookingProfile";

const { TabPane } = Tabs;

function ProductDetail() {
   const [product, setProduct] = useState({ id: "" });
   const [currentTab, setCurrentTab] = useState("1");

   const handleSearch = (values) => {
      setProduct({ id: values?.productId?.trim() });
   };

   return (
      <Page title="Product Detail">
         <Tabs
            onChange={(tab) => setCurrentTab(tab)}
            type="card"
            tabBarExtraContent={
               <Form
                  name="nest-messages"
                  onFinish={handleSearch}
                  style={{ display: "flex", gap: 8 }}
               >
                  <Form.Item
                     name="productId"
                     label="Product Id"
                     style={{ marginBottom: 0 }}
                  >
                     <Input placeholder="Enter product id" />
                  </Form.Item>
                  <Form.Item style={{ marginBottom: 0 }}>
                     <Button type="primary" htmlType="submit">
                        <p className="flex items-center">
                           <SearchOutlined />
                           <span className="ml-2">Submit</span>
                        </p>
                     </Button>
                  </Form.Item>
               </Form>
            }
         >
            <TabPane tab="General Information" key="1">
               {currentTab === "1" && <GeneralInfomation product={product} />}
            </TabPane>
            <TabPane tab="Cooking Profile" key="6">
               {currentTab === "6" && <CookingProfile product={product} />}
            </TabPane>
            <TabPane tab="Content" key="2">
               {currentTab === "2" && <Content product={product} />}
            </TabPane>
            <TabPane tab="Attribute" key="3">
               {currentTab === "3" && <Attribute product={product} />}
            </TabPane>
            <TabPane tab="Customization" key="4">
               {currentTab === "4" && <Customization product={product} />}
            </TabPane>
            <TabPane tab="Availability" key="5">
               {currentTab === "5" && <Availability product={product} />}
            </TabPane>
            <TabPane tab="History" key="7">
               {currentTab === "7" && <History product={product} />}
            </TabPane>
         </Tabs>
      </Page>
   );
}

export default ProductDetail;
