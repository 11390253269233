import { DeleteOutlined, PlayCircleFilled, PlusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Input, Select } from 'antd';
import Page from 'component/Page/index';
import React, { useEffect, useState } from 'react';
import SortableTree from 'react-sortable-tree';
import 'react-sortable-tree/style.css'; // This only needs to be imported once in your app
import {
    addNodeUnderParent,
    removeNodeAtPath,
    changeNodeAtPath,
    toggleExpandedForAll
} from "react-sortable-tree";
import "react-sortable-tree/style.css";
import { useRecoilValue } from 'recoil';
import { userAtom } from '@iso/state/user';
import menuDisplayByodApi from 'api/menuApi';
import { SALE_TYPE } from '@iso/config/index';

const MenuDisplayConfig = () => {
    const [searchString, setSearchString] = useState("");
    const [searchFocusIndex, setSearchFocusIndex] = useState(0);
    const [searchFoundCount, setSearchFoundCount] = useState(null);
    const [originalTreeData, setOriginalTreeData] = useState([]);
    const [treeData, setTreeData] = useState([]);
    const [cateList, setCateList] = useState([])
    const [loading, setLoading] = useState(false);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const user = useRecoilValue(userAtom);
    const inputEl = React.useRef();
    // console.log(treeData);

    function createNode() {
        const value = inputEl.current.value;

        if (value === "") {
            inputEl.current.focus();
            return;
        }

        let newTree = addNodeUnderParent({
            treeData: treeData,
            parentKey: null,
            expandParent: true,
            getNodeKey,
            newNode: {
                id: "123",
                title: value
            }
        });

        setTreeData(newTree.treeData);

        inputEl.current.value = "";
    }

    function updateNode(rowInfo) {
        const { node, path } = rowInfo;
        const { children } = node;

        const value = inputEl.current.value;

        if (value === "") {
            inputEl.current.focus();
            return;
        }

        let newTree = changeNodeAtPath({
            treeData,
            path,
            getNodeKey,
            newNode: {
                children,
                title: value
            }
        });

        setTreeData(newTree);

        inputEl.current.value = "";
    }

    function addNodeChild(rowInfo) {
        let { path } = rowInfo;

        const value = inputEl.current.value;
        // const value = inputEls.current[treeIndex].current.value;

        if (value === "") {
            inputEl.current.focus();
            // inputEls.current[treeIndex].current.focus();
            return;
        }

        let newTree = addNodeUnderParent({
            treeData: treeData,
            parentKey: path[path.length - 1],
            expandParent: true,
            getNodeKey,
            newNode: {
                title: value
            }
        });

        setTreeData(newTree.treeData);

        inputEl.current.value = "";
        // inputEls.current[treeIndex].current.value = "";
    }

    function addNodeSibling(rowInfo) {
        let { path } = rowInfo;

        const value = inputEl.current.value;
        // const value = inputEls.current[treeIndex].current.value;

        if (value === "") {
            inputEl.current.focus();
            // inputEls.current[treeIndex].current.focus();
            return;
        }

        let newTree = addNodeUnderParent({
            treeData: treeData,
            parentKey: path[path.length - 2],
            expandParent: true,
            getNodeKey,
            newNode: {
                title: value
            }
        });

        setTreeData(newTree.treeData);

        inputEl.current.value = "";
        // inputEls.current[treeIndex].current.value = "";
    }

    function removeNode(rowInfo) {
        const { path } = rowInfo;
        setTreeData(
            removeNodeAtPath({
                treeData,
                path,
                getNodeKey
            })
        );
    }

    function updateTreeData(treeData) {
        setTreeData(treeData);
    }

    function expand(expanded) {
        setTreeData(
            toggleExpandedForAll({
                treeData,
                expanded
            })
        );
    }

    function expandAll() {
        expand(true);
    }

    function collapseAll() {
        expand(false);
    }

    const alertNodeInfo = ({ node, path, treeIndex }) => {
        const objectString = Object.keys(node)
            .map((k) => (k === "children" ? "children: Array" : `${k}: '${node[k]}'`))
            .join(",\n   ");

        global.alert(
            "Info passed to the icon and button generators:\n\n" +
            `node: {\n   ${objectString}\n},\n` +
            `path: [${path.join(", ")}],\n` +
            `treeIndex: ${treeIndex}`
        );
    };

    const selectPrevMatch = () => {
        setSearchFocusIndex(
            searchFocusIndex !== null
                ? (searchFoundCount + searchFocusIndex - 1) % searchFoundCount
                : searchFoundCount - 1
        );
    };

    const selectNextMatch = () => {
        setSearchFocusIndex(
            searchFocusIndex !== null ? (searchFocusIndex + 1) % searchFoundCount : 0
        );
    };

    const getNodeKey = ({ treeIndex }) => treeIndex;


    useEffect(() => {
        getDataCate()
        getData()
    }, []);

    const getDataCate = async () => {
        const res = await menuDisplayByodApi.getCateNoneParent();
        if (res) {
            setCateList(res)
        }
    }

    const initDataTree = (data) => {
        // Recursive helper function to process each item
        const processItem = (item) => {
            // Base structure for each item
            let resultItem = {
                title: item?.displays?.name,
                id: item?.id,
                children: [],
                displays_image_url: item?.displays?.image_url,
                // Set dragDisabled and canDelete based on the presence of children
                dragDisabled: !item?.children,
                canDelete: !!item?.children
            };

            // If the item has children, recursively process each child
            if (item?.children) {
                item.children.forEach((child) => {
                    resultItem.children.push(processItem(child)); // Recursive call
                });
            }

            return resultItem;
        };

        // Initialize the result array and process each top-level item
        let result = data?.map(processItem) || [];
        return result;
    };

    const initDataOrigin = (data) => {
        const processItem = (item) => {
            let resultItem = [item?.id];
            if (item?.children) {
                item.children.forEach((child) => {
                    resultItem = [...resultItem, ...processItem(child)];
                });
            }
            return resultItem;
        };

        let result = data?.map(processItem).flat() || [];
        return result;
    };

    const getData = async () => {
        setLoading(true);
        const res = await menuDisplayByodApi.getGrandMenu();
        setLoading(false);
        if (res) {
            const initialData = initDataTree(res)
            const originData = initDataOrigin(res)
            console.log(initialData, originData)
            setOriginalTreeData(originData)
            setTreeData(initialData)

        }
    }

    const handleTreeDataChange = (newTreeData) => {
        console.log(newTreeData)
        setTreeData(newTreeData);
    };

    const handleAddNode = (node) => {
        const newNode = {
            title: node?.displays?.name || node?.title,
            id: node?.id,
            children: [],
            displays_image_url: node?.displays?.image_url || node?.displays_image_url,
            dragDisabled: false,
            canDelete: true,
        }
        console.log(node)
        setTreeData([newNode, ...treeData]);
        setCateList(cateList.filter(item => item?.id !== node?.id))
    };

    const getAllNodeChildFlat = (data) => {
        const processItem = (item) => {
            let resultItem = [item];
            if (item?.children) {
                item.children.forEach((child) => {
                    resultItem = [...resultItem, ...processItem(child)];
                });
            }
            return resultItem;
        };

        let result = data?.map(processItem).flat() || [];
        return result;
    };

    const handleDeleteNode = (node, path) => {
        setTreeData(
            removeNodeAtPath({
                treeData,
                path,
                getNodeKey
            })
        );
        setCateList([...cateList, ...getAllNodeChildFlat([node])])
    };


    const handleEditNode = (node, path) => {
        const updatedTreeData = treeData.map((item, index) => {
            if (index === path[path.length - 1]) {
                return { ...item, title: 'Updated Node' };
            }
            return item;
        });
        setTreeData(updatedTreeData);
    };

    const getAllNode = (treeData, parentId = null) => {
        let result = []
        treeData?.map((item, index) => {
            result.push({ parent_id: parentId, id: item?.id, sorting: index, title: item?.title || item?.name, is_active: true })
            if (item?.children) {
                result = [...result, ...getAllNode(item?.children, item?.id)]
            }
        })
        console.log("🚀 ~ getAllNode ~ result:", result)
        return result
    }

    const handleSave = async () => {
        setLoadingSubmit(true);
        let data = getAllNode(treeData)
        cateList?.map(x => originalTreeData?.includes(x?.id) ? data.push({ parent_id: null, id: x?.id, is_active: false }) : null)
        const res = await menuDisplayByodApi.putMenuDisplay(data).catch(err => {
            console.log(err)
        })
        if (res) {
            getDataCate()
            getData()
        }
        console.log("🚀 ~ handleSave ~ data", data)
        setLoadingSubmit(false);
    }

    return (
        <Page title="Display Menu Management">
            <div className="flex gap-4" id="display-config-page">
                <div className='cate-box'>
                    <h4>Select Category</h4>
                    <div className='mt-6'>
                        {
                            cateList.map((item, index) => (<div className='cate-item py-4 flex justify-between items-center' key={item.id}>
                                {item?.displays?.name || item?.title}
                                <Button onClick={() => handleAddNode(item ? item : item,)}
                                    type="secondary" shape="circle" icon={<PlusOutlined />}
                                />
                            </div>))
                        }
                    </div>
                </div>
                <div className="w-full border-l border-r px-4">
                    <div className='flex justify-between items-center mb-4'>
                        <form
                            style={{ display: "inline-block" }}
                            onSubmit={(event) => {
                                event.preventDefault();
                            }}
                        >
                            <label htmlFor="find-box" className='flex gap-4 items-center'>
                                <h4 className='mb-0'>Search</h4>
                                <Input
                                    id="find-box"
                                    type="text"
                                    value={searchString}
                                    placeholder='Search...'
                                    onChange={(event) => setSearchString(event.target.value)}
                                />
                                <span className=' whitespace-nowrap'>
                                    &nbsp;
                                    {/* {searchFoundCount > 0 ? searchFocusIndex + 1 : 0} */}
                                    {/* &nbsp;/&nbsp; */}
                                    Found: {searchFoundCount || 0}
                                </span>
                            </label>
                        </form>
                        <div>
                            <Button type='secondary' onClick={expandAll}>Reset</Button>
                            <Button type='primary' className='ml-4' onClick={() => handleSave()} loading={loadingSubmit}>Save</Button>
                        </div>
                    </div>
                    <div style={{ minHeight: 400 }}>
                        <SortableTree
                            treeData={treeData}
                            onChange={(treeData) => updateTreeData(treeData)}
                            searchQuery={searchString}
                            searchFocusOffset={2}
                            searchFinishCallback={(matches) => {
                                setSearchFoundCount(matches.length);
                                setSearchFocusIndex(
                                    matches.length > 0 ? searchFocusIndex % matches.length : 0
                                );
                            }}
                            canDrag={({ node }) => !node.dragDisabled}

                            // treeData={treeData}
                            // onChange={handleTreeDataChange}
                            isVirtualized={false}
                            generateNodeProps={({ node, path }) => {
                                return {
                                    buttons: [
                                        <Button type="secondary" shape="circle" icon={<DeleteOutlined />} onClick={() => handleDeleteNode(node, path)}>
                                        </Button>
                                    ],
                                };
                            }}
                        />
                    </div>
                </div>
                <div className="preview-box">
                    {treeData?.map((item, index) => (
                        <div key={index} className="preview-item mb-8">
                            <h3 className='mb-3'>{item?.title}</h3>
                            <div className="preview-item__children">
                                {item?.children?.map((child, indexChild) => (
                                    <div key={indexChild} className="preview-item__child">
                                        <img src={child?.displays_image_url} alt={child?.title} />
                                        <h4>{child?.title}</h4>
                                        <div className="preview-item__sub-children">
                                            {child?.children?.map((subChild, indexSubChild) => (
                                                <div key={indexSubChild} className="preview-item__sub-child">
                                                    <img src={subChild?.displays_image_url} alt={subChild?.title} />
                                                    <h4>{subChild?.title}</h4>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))
                    }
                </div>
            </div>
        </Page >
    );
};

export default MenuDisplayConfig;