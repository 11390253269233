export class AppHelper {

    static convertMoney(money) {
        if (typeof money !== 'number') {
            return null;
        }
        return money.toLocaleString('vi-VN', {
            style: 'currency',
            currency: 'VND'
        });
    }

    static shortenString(str, maxLength) {
        if (typeof str !== 'string' || typeof maxLength !== 'number') {
            return null;
        }
        if (str.length <= maxLength) {
            return str;
        }
        return str.substring(0, maxLength) + '...';
    }
}